import { useState, useContext } from 'react';
import { ReactComponent as AttentionIcon } from 'assets/attention.svg';
import { Flex } from 'styles';
import styled from 'styled-components';
import {
  Button,
  Checkbox,
  InputAdornment,
  FormControlLabel,
  TextField
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import axios from 'axios';
import { endpoints } from 'services/api/endpoints';
import { ConfigContext } from 'context';
import { getPartnerName } from 'partner_configuration';

const PageContainer = styled.div`
  width: ${window.innerWidth < 450 ? window.innerWidth - 40 : 370}px;
  margin: 10px;
`;

const Heading = styled.div`
  color: #494141;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.88px;
  margin: 32px 0;
`;

const AttentionText = styled.div`
  color: #fe832d;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 12px;
`;

const InputContainer = styled.div`
  position: relative;
  margin: 32px 0;
`;

const Info = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const ListItem = styled.li`
  font-size: 14px;
  margin-bottom: 10px;
`;

function AccountDeletionRequest() {
  const [email, setEmail] = useState('');
  const [isRequestSubmited, setRequestSubmited] = useState(false);
  const config = useContext(ConfigContext);
  const LogoIcon = config.loginLogoIcon;
  const [checked, setChecked] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const url: any = window.location.origin;
  const partnerName = getPartnerName(url);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  console.log('email ', email);
  console.log('checked ', checked);

  const handleSubmit = async () => {
    try {
      const payload = {
        email
      };
      axios
        .delete(endpoints.requestAccountDelete, { data: payload })
        .then((res) => {
          console.log('response', res);
          setRequestSubmited(true);
        })
        .catch((err) => {
          console.log('login err', err);
        });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Flex align="center" height="100vh" direction="column" mtop="30">
      <PageContainer>
        <LogoIcon width={60} height={60} />
        <Heading>Request Account Deletion</Heading>
        <Info>
          Why delete your {config.companyName} account with us ? If you are
          facing any technical or billing related issues, you can always write
          to us at {config.email} or call us on {config.phone}
        </Info>

        {!isRequestSubmited && (
          <InputContainer>
            <Flex justify="center" direction="column" gap="8px">
              <AttentionIcon />
              <AttentionText>IMPORTANT:</AttentionText>
            </Flex>
            <Info>
              Before you proceed, please understand that deleting your account
              will have significant, irreversible consequences:
            </Info>
            <ul>
              <ListItem>
                <b>All Data Removed:</b> Your devices, activities, and history
                will be permanently deleted.
              </ListItem>
              {partnerName === 'instaview' && (
                <ListItem>
                  <b>Payments & Refunds:</b> Any active plans will be canceled
                  immediately. You will not receive refunds for any payments
                  made, including monthly and yearly plans.
                </ListItem>
              )}
              <ListItem>
                <b>Irreversible Action:</b> This process cannot be reversed. If
                you create a new account with the same email, it will be a fresh
                start with no previous history.
              </ListItem>
            </ul>
            <TextField
              label="Your account email with us"
              fullWidth
              sx={{ mt: '30px', mb: '20px' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                )
              }}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <FormControlLabel
              sx={{
                fontSize: '14px',
                '& .MuiFormControlLabel-label': {
                  fontSize: '14px'
                }
              }}
              control={<Checkbox checked={checked} onChange={handleChange} />}
              label={
                partnerName === 'instaview'
                  ? 'By confirming, you acknowledge the permanent removal of your account and all associated data, understand there are no refunds for any purchases, and accept that this action is irreversible.'
                  : 'By confirming, you acknowledge the permanent removal of your account and all associated data and accept that this action is irreversible.'
              }
            />
            <Button
              sx={{
                width: '100%',
                mt: '30px'
              }}
              variant="contained"
              disabled={!checked || email.length < 1}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </InputContainer>
        )}

        {isRequestSubmited && (
          <Info style={{ marginTop: '42px' }}>
            Your request to delete your {config.companyName} account is
            submited. You will receive an email, please click on the link in the
            email to verify the request.
          </Info>
        )}
      </PageContainer>
    </Flex>
  );
}

export default AccountDeletionRequest;
