import axios from 'axios';
import { ApiResponse } from './HttpRequest';
import { endpoints } from './endpoints';

export interface SubscriptionBuyType {
  id: string;
  status: string;
  client_secret: string;
}

type UpgradPayload = {
  purchase_id: string;
  stripe_payment_method: string;
};

type TrialResponse = {
  trial: boolean;
};

export interface SubScriptionState {
  subscriptionData: SubscriptionType[];
  isLoading: boolean;
}

export interface SupportedFeatures {
  id: string;
  description: string;
}

export interface Subscription {
  subscriptions: SubscriptionType[];
}

export interface CardDetails {
  brand: string;
  last4: string;
}

export interface SubscriptionType {
  id: string;
  user_id: string;
  product_id: string;
  sku_id: string;
  sku_name: string;
  space_name: string;
  space_id: string;
  currency: string;
  data_retention_days: number;
  devices: IDevice[];
  end_date?: string;
  next_bill_amount?: number;
  next_billing_date?: string;
  paid_amount: number;
  pay_date?: string;
  level: number;
  pay_type: string;
  start_date: string;
  subscribe_status: string;
  subscription_cycle: number;
  supported_camera_count: number;
  supported_features: SupportedFeatures[];
  trial: boolean;
  trial_start_date: number;
  cancel_date?: string;
  purchase_id?: string;
  card_details?: CardDetails;
}

export interface IDevice {
  id: string;
  name: string;
}

export async function WrapperGetSubscriptionDetails(): Promise<
  ApiResponse<Subscription[]>
> {
  const response = await axios.get(endpoints.subscriptionUrl);
  return response.data.subscription;
}

export async function GetSubscriptionDetails(): Promise<
  ApiResponse<Subscription>
> {
  const response = await axios.get(endpoints.subscriptionUrl);
  console.log('GetSubscriptionDetails', response);
  return response.data;
}

export async function UpgradeUserSubscription(
  payload: UpgradPayload
): Promise<ApiResponse<SubscriptionBuyType>> {
  const response = await axios.post(endpoints.subscriptionUpgradeUrl, payload);
  return response;
}

export async function BuyUserSubscription(
  payload: UpgradPayload
): Promise<ApiResponse<SubscriptionBuyType>> {
  const response = await axios.post(endpoints.subscriptionBuyUrl, payload);
  return response;
}

export async function CheckFreeTrialSubscription(): Promise<
  ApiResponse<TrialResponse>
> {
  const response = await axios.get(endpoints.checkTrialUrl);
  return response;
}
